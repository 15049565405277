import { MainProjectSection, sectionUpdateDTO } from "./MainProjectSection";

export default class mainProjectSectionClass {
  section: MainProjectSection | null = null;

  constructor(section: MainProjectSection) {
    this.section = section;
  }

  getFormattedSectionInfo(): sectionUpdateDTO | null {
    if (this.section) {
      const {
        id,
        type: { id: projectSectionTypeId },
        title,
        description,
        mainProjectOrdinal: mainProjectOrdinalNumber,
        ordinal,
        kilometer: kilometrage,
        direction,
        project: { id: projectId },
        deviceOne,
        deviceTwo,
        background: backgroundImage,
        visible,
        cbRadioEnabledOne,
        cbRadioEnabledTwo,
        radarOnLeftSide,
        deviceOneBatteryNumber, 
        deviceTwoBatteryNumber, 
        deviceOneSolarPanelNumber, 
        deviceTwoSolarPanelNumber
      } = this.section;
      return {
        id,
        projectId,
        projectSectionTypeId,
        title,
        ordinalNumber: ordinal,
        kilometrage,
        description: description,
        displayOneTypeId: deviceOne.displayType?.id || null,
        mainboardOneSerial: deviceOne.id || null,
        cbRadioEnabledOne: cbRadioEnabledOne,
        mainboardTwoSerial: deviceTwo.id || null,
        displayTwoTypeId: deviceTwo.displayType?.id || null,
        cbRadioEnabledTwo: cbRadioEnabledTwo,
        mainProjectOrdinalNumber,
        direction,
        backgroundImage,
        visible,
        radarOnLeftSide,
        deviceOneBatteryNumber, 
        deviceTwoBatteryNumber, 
        deviceOneSolarPanelNumber, 
        deviceTwoSolarPanelNumber
      };
    } else return null;
  }

  get isNullPoint() {
    return this.section?.title === "Nullpunkt" && this.section?.type.name === "info";
  }
  get isVisible() {
    return this.section?.visible;
  }
}
