/***
 * Mixing that have the format the kilometric to 3
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [✔] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update to typescript.
 */
import {
  MainProjectSection,
  OpenInformationModal,
  sectionDirectionBatteryNumber,
  sectionDirectionCbRadio
} from "@/model/MainProject/MainProjectSection";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class DeviceStatus extends Vue {
  @Prop({ type: Object }) sec!: MainProjectSection;
  @Prop({ type: String }) devicePosition!: "deviceOne" | "deviceTwo";
  /*----------  Methods  ----------*/
  get isTop(): boolean {
    return this.devicePosition === "deviceOne";
  }
  get isDisplay(): boolean {
    return this.sec.type.name === "display";
  }
  getCbRadioIcon(position: keyof sectionDirectionCbRadio): boolean {
    const direction: sectionDirectionCbRadio = {
      deviceOne: this.sec.direction === "DEFAULT" ? this.sec.cbRadioEnabledOne : this.sec.cbRadioEnabledTwo,
      deviceTwo: this.sec.direction === "DEFAULT" ? this.sec.cbRadioEnabledTwo : this.sec.cbRadioEnabledOne
    };
    return direction[position];
  }
  getBatteryNumber(position: keyof sectionDirectionBatteryNumber): number {
    const direction: sectionDirectionBatteryNumber = {
      deviceOne: this.sec.direction === "DEFAULT" ? this.sec.deviceOneBatteryNumber : this.sec.deviceTwoBatteryNumber,
      deviceTwo: this.sec.direction === "DEFAULT" ? this.sec.deviceTwoBatteryNumber : this.sec.deviceOneBatteryNumber
    };
    return direction[position];
  }
  getStatusClass(status: string, SUPPORT: boolean) {
    if (SUPPORT) return "info";
    switch (status) {
      case "SUPPORT":
        return "info";
      case "MANUELL":
        return "manual-mode";
      case "OK":
        return "active";
      case "WARNING":
        return "pending";
      case "WAITING":
        return "inactive";
      default:
        return "banned";
    }
  }

  checkBatteryStatus(voltage: number) {
    return voltage <= 24 ? "low" : voltage > 24 && voltage < 25 ? "med" : "full";
  }

  openInformationModal() {
    const IS_DEFAULT = this.sec.direction === "DEFAULT";
    /* Use the pass device position if the direction is the default other wise we the other way around if the direction is the OPPOSITE*/
    const DEVICE_POSITION = IS_DEFAULT ? this.devicePosition : this.isTop ? "deviceTwo" : "deviceOne";

    const option: OpenInformationModal = {
      projectId: this.sec.project.id,
      sectionId: this.sec.id,
      trafficReport: this.sec.project.trafficReport,
      boardPosition: this.isDisplay ? DEVICE_POSITION : "deviceOne",
      projectType: {
        isVWA: this.sec.project.vwa,
        isRZP: this.sec.project.roadzipper,
        isFPU: this.sec.project.freeProgrammable,
        isVSU: this.sec.project.variableSign,
        isSCENE: this.sec.project.sceneBased,
        isCWU: this.sec.project.congestionWarning,
        isMq3Aq32SW: this.sec.project.mq3Aq32SW,
        isMq4Aq42SWDEGES: this.sec.project.mq4Aq42SWDEGES,
        isMq3Aq32023: this.sec.project.mq3Aq32023,
        isMq4Aq42SW: this.sec.project.mq4Aq42SW,
        isMq5Aq52SW: this.sec.project.mq5Aq52SW,
        isCwuCascaded: this.sec.project.cwuCascaded,
        isMq1Aq12SW: this.sec.project.mq1Aq12SW,
      }
    };
    this.$emit("open-information-modal", option);
  }
}
