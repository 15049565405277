/***
 * Mixing that have the format the kilometric to 3
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [✔] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update to typescript.
 */
import { Vue, Component, Prop } from "vue-property-decorator";

import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LIcon } from "vue2-leaflet";

@Component({
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LIcon
  }
})
export default class MapHelper extends Vue {
  /*----------  Methods  ----------*/

  // url: String = "https://{s}.tile.osm.org/{z}/{x}/{y}.png";
  url = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  zoom: number = 8;
  defaultCenter = true;
  center: Array<number> = [49.333336, 8.539612];
  bounds: any = null;
  //firstTime = false;
  openPopUps: { [key: string]: any } = {};
  iconSize = 35;
  preferCanvas: boolean = true;

  get dynamicSize() {
    return [this.iconSize, this.iconSize * 1.25];
  }

  get dynamicAnchor() {
    return [this.iconSize / 2, this.iconSize * 1.25];
  }

  sectionStatus(status: string): string {
    switch (status) {
      case "OK":
        return "active";
        break;
      case "ALERT":
        return "banned";
        break;
      case "WAITING":
        return "inactive";
        break;

      default:
        return "pending";
        break;
    }
  }

  zoomUpdated(zoom: number) {
    this.zoom = zoom;
  }

  centerUpdated(center: Array<number>) {
    this.center = center;
  }

  boundsUpdated(bounds: any) {
    this.bounds = bounds;
  }

  handleOpenPopUp(id: number ) {
    if (!(id in this.openPopUps)) {
      this.openPopUps[id] = "open";
      return;
    }
    this.openPopUps[id] === "open";
  }

  openAfterUpdate(id: number | string) {
    const elementOpen: HTMLElement | null = document.querySelector(`[title=ID-${id}]`);
    if (elementOpen) {
      elementOpen.click();
    }
  }

  handleClose(mainboardId: number ) {
    if (this.openPopUps[mainboardId]) {
      this.openAfterUpdate(mainboardId);
      this.openPopUps[mainboardId] = "close";
    }
  }
}
