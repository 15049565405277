
/**
 * Table header item that have sorting capabilities
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "TableHeaderSorting"
})
export default class TableHeaderSorting extends Vue {
  /*----------  Props  ----------*/
  @Prop({ required: true, default: "ASC", type: String }) toggleDirection!: string;
  @Prop({ required: true, default: "", type: String }) direction!: string;
  @Prop({ required: true, default: "", type: String }) headerLabel!: string;

  get toggleSortingDirection(): string {
    return this.getIsASC ? "DESC" : "ASC";
  }
  get getIsASC(): boolean {
    return this.toggleDirection === "ASC";
  }
}
